<template>
  <div class="levelOneDiv" style="background-color: #fff;">
    <!-- <div style="padding: 10px 20px 10px; ">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadList" :key="item.key">{{ item.value }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div v-if="!componentName" class="table_box">
      <div class="box1">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </div>

      <div class="box2">
        <div class="table_right">
          <!-- 搜索筛选 -->
          <el-form :inline="true" :model="searchObj" :rules="rules" ref="form"
            style="align-items: center;margin-bottom: 4px;" class="user-search flex-between" @submit.native.prevent>
            <div>
              <el-form-item label="预算年度" prop="year">
                <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('add', {})">新建</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-download"
                  @click="importExcel()">导入</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-upload2" @click="exportExcel()">导出</el-button>
              </el-form-item>

              <!-- <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-s-data" @click="handleView('charts', {})"
                  >查看统计图</el-button
                >
              </el-form-item> -->
            </div>
          </el-form>

          <div style="position: relative;">
            <el-table v-loading="loading" ref="table" :data="listData" style="width: 100%;position: absolute;"
              height="740px">
              <el-table-column fixed type="index" label="序号" width="60" align="center">
                <template slot-scope="scope">
                  <span>{{ (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column align="center" fixed prop="name" width="80" label="姓名"></el-table-column>
              <el-table-column align="center" fixed prop="population" width="120" label="家庭人口（人）"></el-table-column>

              <el-table-column align="center" label="类型">
                <el-table-column align="center" prop="typeOne" label="移民">
                  <template slot-scope="scope">
                    {{ forMateType1(scope.row.typeOne, scope.row.typeTwo) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="typeTwo" label="非移民">
                  <template slot-scope="scope">
                    {{ forMateType2(scope.row.typeOne, scope.row.typeTwo) }}
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="居住地">
                <el-table-column align="center" prop="cityNames" label="市（州）">
                  <template slot-scope="scope">
                    {{ scope.row.cityNames[1] }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="cityNames" label="县（区）">
                  <template slot-scope="scope">
                    {{ scope.row.cityNames[2] }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="120" prop="county" label="乡（镇）或街道"></el-table-column>
                <el-table-column align="center" width="160" prop="communityName" label="村"></el-table-column>
                <el-table-column align="center" prop="groupName" label="组"></el-table-column>
              </el-table-column>

              <el-table-column align="center" width="210" prop="farmlandAreaPerPerson"
                label="家庭人均耕园地面积（亩/人）"></el-table-column>
              <el-table-column align="center" width="200" prop="forestAreaPerPerson"
                label="家庭人均林地面积（亩/人）"></el-table-column>
              <el-table-column align="center" width="210" prop="grasslandAreaPerPerson"
                label="家庭人均牧草地面积（亩/人）"></el-table-column>
              <el-table-column align="center" width="190" prop="disposableIncomePerPerson" label="家庭人均可支配收入（元）">
                <template slot-scope="scope">
                  {{ formatNumericWithTwoDecimalPlaces(scope.row.disposableIncomePerPerson) }}
                </template>
              </el-table-column>

              <el-table-column align="center" label="家庭年收入（元）">
                <el-table-column width="130" align="center" prop="totalAnnualIncome" label="合计">
                  <template slot-scope="scope">
                    {{ formatNumericWithTwoDecimalPlaces(scope.row.totalAnnualIncome) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="经营性净收入">
                  <el-table-column width="130" align="center" prop="operatingNetIncomeSubtotal" label="小计">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.operatingNetIncomeSubtotal) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="120" prop="agriculturalNetIncome" label="农村净收入">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.agriculturalNetIncome) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="120" prop="forestryNetIncome" label="林果业净收入">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.forestryNetIncome) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="120" prop="livestockNetIncome" label="养殖业净收入">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.livestockNetIncome) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="120" prop="secondaryIndustryNetIncome" label="二三产业净收入">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.secondaryIndustryNetIncome) }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="工资性收入">
                  <el-table-column width="130" align="center" prop="wageIncomeSubtotal" label="小计">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.wageIncomeSubtotal) }}
                    </template>
                  </el-table-column>
                  <el-table-column width="130" align="center" prop="wageIncomeFromLabor" label="其中打工">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.wageIncomeFromLabor) }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="转移性收入">
                  <el-table-column width="130" align="center" prop="transferIncomeSubtotal" label="小计">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.transferIncomeSubtotal) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="120" prop="immigrationSubsidyIncome" label="其中,移民直补">
                    <template slot-scope="scope">
                      {{ formatNumericWithTwoDecimalPlaces(scope.row.immigrationSubsidyIncome) }}
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" width="120" prop="propertyNetIncome" label="财产性净收入">
                  <template slot-scope="scope">
                    {{ formatNumericWithTwoDecimalPlaces(scope.row.propertyNetIncome) }}
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" width="120" prop="consumptionExpenditurePerPerson" label="家庭人均消费支出（元）">
                <template slot-scope="scope">
                  {{ formatNumericWithTwoDecimalPlaces(scope.row.consumptionExpenditurePerPerson) }}
                </template>
              </el-table-column>

              <el-table-column align="center" label="移民满意度（%）">
                <el-table-column align="center" width="120" prop="overallSatisfaction" label="总体满意度">
                  <template slot-scope="scope">
                    {{ formateRate(scope.row.overallSatisfaction) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="120" prop="directSubsidySatisfaction" label="对直补发放的满意度">
                  <template slot-scope="scope">
                    {{ formateRate(scope.row.directSubsidySatisfaction) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="120" prop="immigrationParticipationSatisfaction"
                  label="项目实施中移民参与">
                  <template slot-scope="scope">
                    {{ formateRate(scope.row.immigrationParticipationSatisfaction) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="120" prop="implementationEffectSatisfaction" label="对项目实施效果的满意度">
                  <template slot-scope="scope">
                    {{ formateRate(scope.row.implementationEffectSatisfaction) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="120" prop="appealHandlingSatisfaction" label="对移民申诉渠道和问题处理的满意度">
                  <template slot-scope="scope">
                    {{ formateRate(scope.row.appealHandlingSatisfaction) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="120" prop="currentLivingSatisfaction" label="对目前生产生活的总体满意度">
                  <template slot-scope="scope">
                    {{ formateRate(scope.row.currentLivingSatisfaction) }}
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" fixed="right" label="操作" width="90">
                <template slot-scope="scope">
                  <div class="flex">
                    <el-link type="primary" @click="handleView('view', scope.row)">查看</el-link>
                    &nbsp; &nbsp;
                    <el-link type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </div>

    <formHandle v-else="componentName" ref="formHandle" @refreshTable="refreshTable" @handleBack="handleBack">
    </formHandle>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
    <!-- 分页组件 -->
  </div>
</template>

<script>
import {
  _SampleHouseholdPage,
  _SampleHouseholdExport,
  _SampleHouseholdDelete
} from '@/api/modular/postImmigrationService/monitorAndEvaluate/sampleHouseholds.js'

import Pagination from '@/components/Pagination'
import templateTitle from '@/components/templateView/templateTitle.vue'
import formHandle from '@/views/postImmigrationService/monitorAndEvaluate/incomeLevel/sampleHouseholds/components/formHandle.vue'
import templateTree from '@/components/templateView/templateTree.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'

import { _getLevelRegion } from '@/api/keepHome'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'

export default {
  data() {
    return {
      type: '',
      showActions: false,
      selectedRows: [], // 当前选中的行
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false, //是显示加载
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择预算年度', trigger: 'blur' }]
      },
      listData: [],
      treeData: [],
      breadList: [
        {
          id: Math.random(),
          value: '列表页'
        }
      ],
      componentName: '',
      downloadObj: {},
      dck: [],
      OrgId: null,
      IsLevel: false,
      OrgPids: null,
      years: [],
      typeArr: [],
      typeTwoArr: []
    }
  },
  // 注册组件
  components: {
    Pagination,
    templateTitle,
    formHandle,
    tempalteImport,
    templateTree,
    budgetYear
  },
  created() {
    this.init()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    async init() {
      await this.getType12()
      this.getLevelRegion()
      this.getPage()
    },
    getType12() {
      let params = {
        Code: 'SampleHouseholdsType'
      }
      sysDictTypeDropDown(params).then(res => {
        if (res.code == 200) {
          this.typeArr = res.data

        }
      })
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },
    forMateType1(typeOne, typeTwo) {
      if (typeOne && typeTwo) {
        let obj = this.typeArr.find(ele => ele.id == typeOne && ele.value == '移民')
        let typeTwoArr = obj?.children
        let obj1 = typeTwoArr?.find(ele => ele.id == typeTwo)
        return obj1?.value
      } else if (typeOne) {
        let obj = this.typeArr?.find(ele => ele.id == typeOne && ele.value == '移民')

        return obj ? '--' : ''
      } else {
        return ''
      }
    },
    forMateType2(typeOne, typeTwo) {
      if (typeOne && typeTwo) {
        let obj = this.typeArr.find(ele => ele.id == typeOne && ele.value == '非移民')
        let typeTwoArr = obj?.children
        let obj1 = typeTwoArr?.find(ele => ele.id == typeTwo)
        return obj1?.value
      } else if (typeOne) {
        let obj = this.typeArr?.find(ele => ele.id == typeOne && ele.value == '非移民')
        return obj ? '--' : ''
      } else {
        return ''
      }
    },
    refreshTable() {
      this.getPage()
    },
    refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    formateRate(value) {
      if (value) {
        let num = value * 100
        return num.toFixed(2) + '%'
      } else {
        return ''
      }
    },
    exportExcel() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let params = {
            year: this.searchObj.year,
            OrgId: this.OrgId, //行政区域编号
            OrgName: this.downloadObj.name, //行政区域名称
            OrgPids: this.downloadObj.pids //行政区域编号path
          }
          // return
          _SampleHouseholdExport(params).then(res => {
            if (res.status == 200) {
              this.downloadfile(res)
            } else {
              this.$message.error('导出失败')
            }
          })
        } else {
          this.$message.error('请选择导出年度')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.searchObj.year, this.years, '1-7-4')
    },

    handleBack() {
      this.breadList.splice(1, 1)
      this.componentName = ''
    },
    // 获取page列表
    getPage() {
      const { currentPage, pageSize, total } = this.pageparm
      this.loading = true
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchObj,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }

      _SampleHouseholdPage(params)
        .then(res => {
          if (res.code == 200) {

            this.listData = res.data.rows
            this.pageparm.total = res.data.totalRows



            this.loading = false
          } else {
            this.loading = false
            this.$message.error('获取失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = [{ id: row.id }]
          _SampleHouseholdDelete(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.getPage()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索事件
    search() {
      this.pageparm.currentPage = 1
      this.getPage()
    },
    refreshYear(year, years) {
      this.searchObj.year = year
      this.years = years
      this.pageparm.currentPage = 1
      this.getPage()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.searchObj = {
          year: nowYear - 1
        }
      } else {
        this.searchObj = {
          year: nowYear - 2
        }
      }

      this.OrgId = null
      this.IsLevel = false
      this.OrgPids = null
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }

      this.$refs.budgetYear.reset(this.searchObj.year)
      this.$refs.templateTree.resetLevel()

      this.getPage()
    },
    handleAdd(type) {
      let obj = {
        id: Math.random(),
        value: '新增'
      }
      this.breadList.splice(this.breadList.length, 0, obj)
      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.add(type,this.typeArr)
      })
    },

    handleView(type, row) {
      let obj = {
        id: Math.random(),
        value: type == 'view' ? '详情' : '查看统计图'
      }
      this.breadList.splice(this.breadList.length, 0, obj)

      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.view(type, row,this.typeArr)
      })
    },

    refreshTable() {
      this.getPage()
    },

    // 选择select
    handleSelectionChange(selection) {
      // console.log(selection);//点击的那行数据arr
      this.selectedRows = selection
      this.showActions = selection.length > 0
    },
    isSelect({ row }) {
      const checkIdList = this.selectedRows.map(item => item.data1)
      if (checkIdList.includes(row.data1)) {
        return {
          color: '#e17009'
        }
      }
    },

    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 0 !important;
  background-color: #fff !important;
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;

  td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f7fa !important;
  }

  td:first-child {
    border-left: 0 !important;
  }

  td:last-child {
    border-right: 0 !important;
  }
}
</style>
